// 
// 
// navbar.scss
//
//


// Bootstrap overrides
//
// General overrides for default styles

.nav-link{
  transition: color .35s ease;
  &:focus{
    outline: none;
  }
}

.container > .navbar{
  padding-left: 0;
  padding-right: 0;
}

.navbar .row{
  width: 100%;
}

.navbar.bg-dark{
  .navbar-nav{
    .nav-item{
      > a:not(.nav-link){
        font-weight: $font-weight-bold;
        color: #fff;
      }
    }
    .dropdown-menu {
      background: $gray-800;
      border-color: $gray-900;
      padding: 0;
      .dropdown-item:hover {
        background-color: $gray-900;
      }
      .dropdown-item:focus {
        background-color: $gray-900;
      }      
    }
  }
}

.navbar-dark{
  .nav-item{
    > *{
      color: #fff;
    }
    > a:not(.nav-link){
      font-weight: $font-weight-bold;
    }
  }
}

.navbar-nav.nav-fill{
  flex-grow: 1;
}

.navbar.bg-dark .navbar-nav 



// Navbar additions
//
// 

.navbar-dark{
  .navbar-toggler{
    i{
      color: #fff;
    }
  }
}

// Give inline forms some space below on sm and down
@include media-breakpoint-down(sm) {
  .navbar .form-inline{
    &:not(:last-child){
      margin-bottom: $spacer/2;
    }
  }
}

// Navbar positioning
//
// 

.nav-container{
  .position-absolute, .position-fixed{
    z-index: $zindex-sticky;
    width: 100%;
    top: 0;
  }
}

.navbar-brand-abs-center {
  position: absolute;
  top: .75rem;
  left: 50%;
  margin-left: -20px;
  margin-right: 0;
}

@include media-breakpoint-down(lg) {
  .nav-container{
    .position-absolute{
      position: relative !important;
      &.navbar-dark{
        background: theme-color("dark");
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar.sticky-top.navbar-expanded,
  .navbar.fixed-top.navbar-expanded {
      max-height: 100%;
      max-height: 100vh;
      overflow-y: auto;
  }
}

@include media-breakpoint-up(lg) {
    .navbar-brand-lg-inline {
        position: relative;
        top: auto;
        left: auto;
        margin-left: 0;
        margin-right: 0;
    }
}